import Image from 'next/image';
import Link from 'next/link';
import scamTypes from '../../public/files/scamcategories.json';
import { SocialIcon } from 'react-social-icons';
import { Divider } from '@heroui/react';

const letters = Array.from(Array(26)).map((_, i) => String.fromCharCode(i + 65));
const numbers = [...Array(10).keys()].map((i) => i.toString());
const filters = [...letters, ...numbers];

const Footer = () => {
    return (
        <footer className="mt-auto bg-white">
            <div className="container">
                <div className="grid gap-x-4 gap-y-10 py-7 sm:grid-cols-3 lg:grid-cols-3 lg:py-[60px]">
                    <div className="p-2">
                        <ul className="flex flex-col gap-3 font-bold">
                            <li className="mb-3 text-lg font-extrabold text-black dark:text-white">For Consumer</li>
                            <li>
                                <Link prefetch={false} href="/report-a-scam" className="inline-block transition hover:scale-110 hover:text-primary">
                                    Report a Scam
                                </Link>
                            </li>
                            <li>
                                <Link prefetch={false} href="/companies" className="inline-block transition hover:scale-110 hover:text-primary">
                                    Search a Company
                                </Link>
                            </li>
                            <li>
                                <Link prefetch={false} href="/companies/directory" className="inline-block transition hover:scale-110 hover:text-primary">
                                    Company Directory
                                </Link>
                            </li>
                            <li>
                                <Link prefetch={false} href="/scam-types" className="inline-block transition hover:scale-110 hover:text-primary">
                                    View Scam Types
                                </Link>
                            </li>
                            <li>
                                <Link prefetch={false} href="/scam-prevention" className="inline-block transition hover:scale-110 hover:text-primary">
                                    Scam Prevention
                                </Link>
                            </li>
                            <li>
                                <Link
                                    prefetch={false}
                                    href="/stay-protected/what-to-do-if-you-have-been-scammed"
                                    className="inline-block transition hover:scale-110 hover:text-primary"
                                >
                                    Help & Info
                                </Link>
                            </li>
                            {/* {scamTypes.map((type) => (
                                <li key={type.Title}>
                                     <Link prefetch={false} href={`${type.LinkUrl}`} className="inline-block transition hover:scale-110 hover:text-primary">
                                        {type.Title}
                                    </Link>
                                </li>
                            ))} */}
                        </ul>
                    </div>
                    {/* <div>
                            <ul className="flex flex-col gap-3 font-bold">
                                <li className="mb-3 text-lg font-extrabold text-black dark:text-white">Services</li>
                                <li>
                                     <Link href="/services-detail" className="inline-block transition hover:scale-110 hover:text-primary">
                                        UI/UX Design
                                    </Link>
                                </li>
                                <li>
                                     <Link href="/services-detail" className="inline-block transition hover:scale-110 hover:text-primary">
                                        Web Development
                                    </Link>
                                </li>
                                <li>
                                     <Link href="/services-detail" className="inline-block transition hover:scale-110 hover:text-primary">
                                        Digital Marketing
                                    </Link>
                                </li>
                                <li>
                                     <Link href="/services-detail" className="inline-block transition hover:scale-110 hover:text-primary">
                                        App Development
                                    </Link>
                                </li>
                            </ul>
                        </div> */}
                    <div className="p-2">
                        <ul className="flex flex-col gap-3 font-bold">
                            <li className="mb-3 text-lg font-extrabold text-black dark:text-white">For Business</li>
                            <li>
                                <Link prefetch={false} href="/business" className="inline-block transition hover:scale-110 hover:text-primary">
                                    Claim Your Business
                                </Link>
                            </li>
                            <li>
                                <Link prefetch={false} href="/business" className="inline-block transition hover:scale-110 hover:text-primary">
                                    API & Data Feed
                                </Link>
                            </li>
                            <li>
                                <Link prefetch={false} href="/contact-us" className="inline-block transition hover:scale-110 hover:text-primary">
                                    Incorporate Our Logo
                                </Link>
                            </li>
                            <li>
                                <Link prefetch={false} href="/contact-us" className="inline-block transition hover:scale-110 hover:text-primary">
                                    Become Our Partner
                                </Link>
                            </li>
                            <li>
                                <Link prefetch={false} href="/business" className="inline-block transition hover:scale-110 hover:text-primary">
                                    Advertise With Us
                                </Link>
                            </li>
                            <li>
                                <Link prefetch={false} href="/business" className="inline-block transition hover:scale-110 hover:text-primary">
                                    Submit a Website or List your Business
                                </Link>
                            </li>
                            <li>
                                <Link
                                    prefetch={false}
                                    href="/sg-industrial-classification"
                                    className="inline-block transition hover:scale-110 hover:text-primary"
                                >
                                    Singapore Standard Industrial Classification
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="p-2">
                        <h4 className="mb-6 text-lg font-extrabold text-black">About Scam.SG</h4>
                        {/* <p className='text-base font-semibold'> */}
                        Scam.SG is the largest Singapore business company review and authenticity platform that provides business scam analysis and aggregate
                        business authenticity to help consumers and/or business associates reduce the risk of falling into a scam. Our analysis uses our own
                        proprietary algorithms to review, verify and authenticate various businesses and enterprises in Singapore.
                    </div>
                    {/* <div>
                            <ul className="flex flex-col gap-3 font-bold">
                                <li className="mb-3 text-lg font-extrabold text-black dark:text-white">Information</li>
                                <li>164 Brighton Ave, Allston, Maine, U.S. - 02134</li>
                                <li>
                                    <a href="tel:+(617) 254-2333" className="inline-block transition hover:scale-110 hover:text-primary">
                                        +(617) 254-2333
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                </div>
                {/* Disclaimer */}
                <div className="flex flex-col gap-3 pb-12">
                    <Link href="/disclaimer" className="mb-1 text-lg font-bold text-black hover:underline">
                        Disclaimer
                    </Link>
                    <p>
                        The information provided on Scam.SG (the “Website”) is sourced from publicly available data. Whilst we strive to ensure the accuracy and
                        reliability of the data presented, we cannot guarantee its completeness or timeliness. The scores on the Website are based on the data
                        made available to us. As such, the scores may vary over time depending on the data available on this entity at the material time. If you
                        are the business owner of this entity and would like an update/re-assessment of your score, please contact us for a discussion and/or to
                        submit any additional information that could impact our assessment. Kindly note that the score displayed on the Website remains wholly
                        at our discretion. Users accessing and utilising information from the Website do so at their own risk. Read more at our
                        <Link href="/disclaimer" className="text-blue-500 hover:underline">
                            {' '}
                            disclaimer page
                        </Link>
                        .
                    </p>
                </div>
                <Divider />
                {/* bottom for additional links */}
                <div className="py-5">
                    <div className="container ">
                        <div className=" items-center justify-center gap-4 text-center font-bold dark:text-white md:flex md:flex-row">
                            <div>
                                <Link prefetch={false} href="/privacy-policy" className="transition hover:text-primary">
                                    Privacy Policy
                                </Link>
                            </div>
                            <div>
                                <Link prefetch={false} href="/terms-and-conditions" className="transition hover:text-primary">
                                    Terms & Conditions
                                </Link>
                            </div>
                            <div>
                                <Link prefetch={false} href="/disclaimer" className="transition hover:text-primary">
                                    Disclaimer
                                </Link>
                            </div>
                            <div>
                                <Link prefetch={false} href="/notice-and-take-down" className="transition hover:text-primary">
                                    Notice & Take Down
                                </Link>
                            </div>
                            {/* <div>
                                <Link prefetch={false} href="/dispute-resolution" className="transition hover:text-primary">
                                    Dispute Resolution
                                </Link>
                            </div> */}
                            <div>
                                <Link prefetch={false} href="/contact-us" className="transition hover:text-primary">
                                    Copyright
                                </Link>
                            </div>
                            <div>
                                <Link prefetch={false} href="/sitemap" className="transition hover:text-primary">
                                    Sitemap
                                </Link>
                            </div>
                        </div>
                        {/* <div className="mt-4 items-center justify-center gap-4 text-center font-bold dark:text-white md:flex">
                            <SocialIcon network="facebook" url="#" color="#000000" className="!h-8 !w-8 fill-secondary md:!h-10 md:!w-10" />
                            <SocialIcon network="twitter" url="#" color="#000000" className="!h-8 !w-8 fill-secondary md:!h-10 md:!w-10" />
                            <SocialIcon network="linkedin" url="#" color="#000000" className="!h-8 !w-8 fill-secondary md:!h-10 md:!w-10" />
                            <SocialIcon network="youtube" url="#" color="#000000" className="!h-8 !w-8 fill-secondary md:!h-10 md:!w-10" />
                            <SocialIcon network="instagram" url="#" color="#000000" className="!h-8 !w-8 fill-secondary md:!h-10 md:!w-10" />
                            <SocialIcon network="pinterest" url="#" color="#000000" className="!h-8 !w-8 fill-secondary md:!h-10 md:!w-10" />
                        </div> */}
                        <div className="mx-auto mt-4 flex items-center justify-center text-center font-bold dark:text-white">
                            <Image src="/assets/images/logo.png" alt="Scam.SG" width={144} height={25} className="mt-4 aspect-auto w-36 md:mt-0" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-blue-100 py-5 dark:border-t-2 dark:border-white/5 dark:bg-none">
                <div className="container">
                    <div className="items-center justify-between text-center font-bold dark:text-white md:flex">
                        <div>
                            © {new Date().getFullYear() + ' '}
                            Scam.SG
                        </div>
                        {/* <div>
                                Need help? Visit the{' '}
                                 <Link prefetch={false} href="/#contactus" className="text-secondary transition hover:text-primary">
                                    Contact Us
                                </Link>
                            </div> */}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
