// app/providers.tsx
'use client';

import { HeroUIProvider } from '@heroui/react';
import { Provider } from 'react-redux';
import store from '../store/index';

export function Providers({ children }: { children: React.ReactNode }) {

    return (
        <Provider store={store}>
            <HeroUIProvider>{children}</HeroUIProvider>
        </Provider>
    );
}
