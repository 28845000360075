'use client'

import { useEffect, useRef } from 'react'
import Link from 'next/link'
import { ArrowRight } from 'lucide-react'

export default function AnnouncementBar({ animate = false }: { animate?: boolean }) {
  const textRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    const textElement = textRef.current
    if (textElement && animate) {
      const animateText = () => {
        const textWidth = textElement.offsetWidth
        const parentWidth = textElement.parentElement?.offsetWidth || 0
        textElement.style.transform = `translateX(${(parentWidth - textWidth) / 2}px)`
        textElement.style.animation = 'none'
        void textElement.offsetWidth // Trigger reflow
        textElement.style.animation = `moveText ${(textWidth + parentWidth) / 50}s linear infinite`
      }

      animateText()
      window.addEventListener('resize', animateText)

      return () => {
        window.removeEventListener('resize', animateText)
      }
    }
  }, [animate])

  return (
     <Link href="/business/pre-launch-offer" className="block bg-primary text-center text-primary-foreground py-2 overflow-hidden relative">
      <span ref={textRef} className={`inline-flex items-center text-center whitespace-nowrap ${animate ? 'animate-moveText' : ''}`}>
        ⏰ Exclusive Launch Offer: Get Premium at $199/month - Limited Time Only!
        <ArrowRight className="ml-2 h-4 w-4" />
      </span>
    </Link>
  )
}