"use client";

import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@heroui/dropdown";
import { Avatar } from "@heroui/avatar";
import { User as UserIcon, Settings, LogOut } from "lucide-react";
import { signOut, useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/navigation";

const LoginBadge = () => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const user = session?.user;

  const handleSignOut = async () => {
    await signOut({ redirect: false });
    router.refresh();
    router.push("/login");
  };

  if (status === "loading") {
    return <div className="animate-pulse w-8 h-8 bg-gray-200 rounded-full" />;
  }

  return (
    <>
      {user && (
        <Dropdown>
          <DropdownTrigger>
            {user?.image ? (
              <Avatar src={user.image} name={user.name?.split(" ")[0] || ""} />
            ) : (
              <Avatar name={user.name?.split(" ")[0] || "User"}/>
            )}
          </DropdownTrigger>
          <DropdownMenu aria-label="User actions">
            <DropdownItem key="settings">
              <Link href="/settings" className="flex items-center">
                <Settings className="mr-2" />
                Profile
              </Link>
            </DropdownItem>
            <DropdownItem key="logout">
              <button 
                onClick={handleSignOut}
                className="flex items-center w-full px-2 py-1"
              >
                <LogOut className="mr-2" />
                Logout
              </button>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      {!user && (
        <Link href="/login" className="flex items-center bg-primary hover:text-white p-2 rounded-md">
          <span className="block lg:hidden text-white">Login</span>
          <UserIcon className="hidden lg:block" color="white" />
        </Link>
      )}
    </>
  );
};

export default LoginBadge;